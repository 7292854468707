import { React, Fragment, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import { useGetRequest, sendPostRequest, sendGetRequest } from "../Client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { imageURL } from "../config";
import { saveAs } from 'file-saver'


function Products() {
  const { divisionID } = useParams();

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});

  // const { status, data } = useGetRequest(
  //   "categories/products/" +
  //     process.env.REACT_APP_ACCOUNT_ID +
  //     "/" +
  //     categoryID,
  //   {}
  // );

  const downloadImage = (url) => {
    saveAs(url, 'image.jpg') // Put your image URL here.
  }

  const { status, data } = useGetRequest(
    "divisions/products/" + process.env.REACT_APP_ACCOUNT_ID + "/" + divisionID,
    {}
  );

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Added to basket");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // adding to basket
  const basketAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return basketAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["basket"] });
    },
  });

  if (status === "fetched") {
    return (
      <>
        <Navbar />

        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {data.division}
            </h1>

            <div className="bg-white">
              <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8 mb-20">
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {data?.products?.map((product, key) => (
                    <>
                      <div
                        key={key}
                        className="group relative"
                        onClick={() => downloadImage(imageURL+product.image2.length > 0 ? imageURL + product.image2 : imageURL + product.image)}
                      >
                        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-95 lg:h-80">
                          <img
                            src={
                              product.image
                                ? imageURL + product.image
                                : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                            }
                            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                          />
                        </div>

                        <div>
                          <h3 className="font-medium text-gray-900 mt-4">
                            {product.productCode}
                          </h3>
                        </div>

                        <div>
                          <h3 className="text-sm text-gray-500 mb-2 mt-1">
                            {product.description}
                          </h3>
                        </div>

                        <div>
                          <h3 className="text-sm text-gray-500 mb-2 mt-1">
                            {product.category1Name
                              ? product.category1Name
                              : "N/A"}{" "}
                            /{" "}
                            {product.category2Name
                              ? product.category2Name
                              : "N/A"}{" "}
                            /{" "}
                            {product.category3Name
                              ? product.category3Name
                              : "N/A"}
                          </h3>
                        </div>

                        <tbody className="divide-y divide-gray-200 bg-white">
                          {product.unitPrice > 0 ? (
                            <tr>
                              <td className="w-2/3 text-sm text-gray-500">
                                Unit Price:
                              </td>
                              <td className="w-2/3 text-sm text-gray-500">
                                ${product.unitPrice}
                              </td>
                            </tr>
                          ) : null}

                          {product.cartonPrice > 0 ? (
                            <tr>
                              <td className="w-2/3 text-sm text-gray-500">
                                Carton Price:
                              </td>
                              <td className="w-2/3 text-sm text-gray-500">
                                ${product.cartonPrice}
                              </td>
                            </tr>
                          ) : null}

                          <tr>
                            <td className="w-2/3 text-sm text-gray-500 mb-2">
                              Available:
                            </td>
                            <td className="w-2/3 text-sm text-gray-500">
                              {product.stockIn - product.stockOut}
                            </td>
                          </tr>

                        </tbody>

                        <div className="mt-5"></div>

                        <tbody>
                          <tr className="h-10">
                            <td className="w-1/4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="qty"
                                onChange={handleChange}
                                id="qty"
                                className="border block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </td>
                            <td className="w-1/2 text-sm text-gray-500 pb-2 pl-5">
                              <button
                                type="button"
                                inputmode="numeric"
                                className="mt-2 items-start rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500"
                                onClick={async () => {
                                  try {
                                    await doAddMutation({
                                      path: "basket/add",
                                      body: {
                                        inventoryID: product.inventoryID,
                                        description: product.description,
                                        image: product.image,
                                        unitPrice: product.unitPrice,
                                        quantity: inputs.qty,
                                      },
                                    });
                                  } catch (e) {
                                    console.log(e);
                                    setInputs({});
                                  }
                                }}
                              >
                                Add To Basket
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Products;
