import { React, Fragment, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Cookies from "js-cookie";
import { useGetRequest } from "../Client";

function Dashboard() {
  // const { status, data } = useGetRequest(
  //   "inventory/categories/" + process.env.REACT_APP_ACCOUNT_ID,
  //   {}
  // );

  const { status, data } = useGetRequest(
    "inventory/divisions/" + process.env.REACT_APP_ACCOUNT_ID,
    {}
  );



  if (status === "fetched") {
    return (
      <>
        <Navbar />

        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Order Online
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              Welcome to the {process.env.REACT_APP_COMPANY} Online Inventory
              Distribution Center.
            </p>
            <p class="text-lg leading-8 text-gray-600">
              Please choose a category below to get started.
            </p>
          </div>
        </div>

        <div className="bg-white mb-10">
          <div className="py-4 sm:py-4 xl:mx-auto xl:max-w-7xl xl:px-8">
            <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                <Link to="/">Shop by Division</Link>
              </h2>
            </div>

            <div className="bg-white">
              <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8 mb-4">
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {data?.divisions?.map((item) =>
                    item.inventoryFreq > 0 ? (
                      <Link to={`/products/${item.divisionID}`}>
                        <a
                          key={item.divisionName}
                          href={item.href}
                          className="relative flex h-40 w-32 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                        >
                          <span
                            aria-hidden="true"
                            className="absolute inset-0 bg-slate-800"
                          >
                            <img
                              src={item.imageSrc}
                              alt=""
                              className="h-40 w-full"
                            />
                          </span>
                          <span
                            aria-hidden="true"
                            className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                          />
                          <span className="relative mt-auto text-center text-xl font-bold text-white">
                            {item.divisionName}
                          </span>
                        </a>
                      </Link>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Dashboard;
