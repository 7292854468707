import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { sendPostRequest, useGetRequest, sendGetRequest } from "../Client";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { imageURL } from "../config";

function Checkout() {
  const navigate = useNavigate();

  // const [addressName, setAddressName] = useState();
  const [companyName, setCompanyName] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [country, setCountry] = useState("United States");

  const [inputs, setInputs] = useState({ shippedWith: 0 });
  const [addressBook, setAddressBook] = useState(0);
  const [liftGateRequired, setLiftGateRequired] = useState(false);
  const [addAddressBook, setAddAddressBook] = useState(false);
  const [newAddressName, setNewAddressName] = useState();

  const { status: statusDropdownAddresses, data: dataDropdownAddresses } =
    useGetRequest("addresses/dropdown", {});

  const { status: statusAvailableServices, data: dataAvailableServices } =
    useGetRequest("checkout/services/" + process.env.REACT_APP_ACCOUNT_ID, {});

  useEffect(() => {
    getAddressBook();
  }, [addressBook]);

  const getAddressBook = async () => {
    const res = await sendGetRequest("addresses/" + addressBook, {});

    // setAddressName(
    //   res?.address[0]?.addressName ? res?.address[0]?.addressName : ""
    // );
    setCompanyName(
      res?.address[0]?.companyName ? res?.address[0]?.companyName : ""
    );
    setFirstName(res?.address[0]?.firstName ? res?.address[0]?.firstName : "");
    setLastName(res?.address[0]?.lastName ? res?.address[0]?.lastName : "");
    setPhoneNumber(
      res?.address[0]?.phoneNumber ? res?.address[0]?.phoneNumber : ""
    );
    setEmail(res?.address[0]?.email ? res?.address[0]?.email : "");
    setAddressLine1(
      res?.address[0]?.addressLine1 ? res?.address[0]?.addressLine1 : ""
    );
    setAddressLine2(
      res?.address[0]?.addressLine2 ? res?.address[0]?.addressLine2 : ""
    );
    setCity(res?.address[0]?.city ? res?.address[0]?.city : "");
    setState(res?.address[0]?.state ? res?.address[0]?.state : "");
    setZip(res?.address[0]?.zip ? res?.address[0]?.zip : "");
  };

  const sumChargeTotal = () => {
    let total = 0;

    checkout?.basket.forEach((a) => {
      total += Number(a.quantity * a.unitPrice);
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  // confirmation notifications
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Order placed");
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(companyName == "" || firstName == "" || lastName == "" || email == "" || addressLine1 == "" || city == "" || zip == "" || country == "")
      { 
        notyf.error("Please fill in all required fields");
        return;
      }

    const requestBody = {
      customerID: process.env.REACT_APP_ACCOUNT_ID,
      status: 2,
      basket: checkout.basket,
      specialInstructions: inputs.specialInstructions,
      shippedWith: inputs.shippedWith,
      liftGateRequired: liftGateRequired,
      // addressBook: addressBook,
      companyName: companyName,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      addAddressBook: addAddressBook ? 1 : 0,
      newAddressName: newAddressName,
    };

    addedNotyf();

    const res = await sendPostRequest("order/add", requestBody);

    navigate(`/my-orders`, { replace: true, state: { deleted: 1 } });
  };

  // loading basket
  const { data: checkout, isLoading } = useQuery({
    queryFn: () => sendGetRequest("basket", {}),
    queryKey: ["basket"],
  });

  if (
    statusDropdownAddresses == "fetched" &&
    statusAvailableServices == "fetched" &&
    !isLoading
  ) {
    return (
      <>
        <Navbar />

        <div className="bg-white">
          <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
            <h1 className="sr-only">Order information</h1>

            <section
              aria-labelledby="summary-heading"
              className="bg-gray-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
            >
              <div className="mx-auto max-w-lg lg:max-w-none">
                <h2
                  id="summary-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Order summary
                </h2>

                <ul
                  role="list"
                  className="divide-y divide-gray-200 text-sm font-medium text-gray-900"
                >
                  {checkout?.basket.map((product) => (
                    <li
                      key={product.id}
                      className="flex items-start space-x-4 py-6"
                    >
                      <img
                        src={imageURL + product.image}
                        className="h-20 w-20 flex-none rounded-md object-cover object-center"
                      />
                      <div className="flex-auto space-y-1">
                        <h3>{product.description}</h3>
                        <p className="text-gray-500">
                          {" "}
                          Unit Price: $
                          {(Math.round(product.unitPrice * 100) / 100).toFixed(
                            2
                          )}
                        </p>
                        <p className="text-gray-500">
                          Total Price: $
                          {(
                            Math.round(
                              product.unitPrice * product.quantity * 100
                            ) / 100
                          ).toFixed(2)}
                        </p>
                        <p className="text-gray-500">Qty: {product.quantity}</p>
                      </div>
                      <p className="flex-none text-base font-medium">
                        {product.totalPrice}
                      </p>
                    </li>
                  ))}
                </ul>

                <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Subtotal</dt>
                    <dd>${sumChargeTotal()}</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Shipping</dt>
                    <dd>$0.00</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Taxes</dt>
                    <dd>$0.00</dd>
                  </div>

                  <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base">${sumChargeTotal()}</dd>
                  </div>
                </dl>
              </div>
            </section>

            <form
              onSubmit={handleSubmit}
              className="px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16"
            >
              <div className="mx-auto max-w-lg lg:max-w-none">
                {/* <div>{JSON.stringify(inputs)}</div> */}
                <section aria-labelledby="shipping-heading" className="">
                  <h2
                    id="shipping-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    Shipping Information
                  </h2>

                  <div className="mt-5 w-full mb-10">
                    <label
                      htmlFor="addressBookID"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      My Address Book
                    </label>

                    <select
                      id="addressBookID"
                      name="addressBookID"
                      onChange={(e) => setAddressBook(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value={0}>Select an address</option>
                      {dataDropdownAddresses?.addressDropdown.map((address) => (
                        <option
                          key={address.addressID}
                          value={address.addressID}
                        >
                          {address.addressName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* {addressBook > 0 ? ( */}
                  <div className="w-2/2">
                    {/* <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="addressName"
                        className="text-sm font-medium leading-6 text-gray-900"
                      >
                        Address Name*
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="addressName"
                          name="addressName"
                          onChange={(e) => {
                            setAddressName(e.target.value);
                          }}
                          value={addressName}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div> */}

                    <div className="w-1/2 inline-block">
                      <label
                        htmlFor="companyName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Company Name
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="companyName"
                          name="companyName"
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                          value={companyName}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact First Name*
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                          value={firstName}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="w-1/2 inline-block">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact Last Name*
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          value={lastName}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="phoneNumber"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="phoneNumber"
                          name="phoneNumber"
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          value={phoneNumber}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 inline-block">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email*
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="addressLine1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address Line 1*
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="addressLine1"
                          name="addressLine1"
                          onChange={(e) => {
                            setAddressLine1(e.target.value);
                          }}
                          value={addressLine1}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="addressLine2"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address Line 2
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="addressLine2"
                          name="addressLine2"
                          onChange={(e) => {
                            setAddressLine2(e.target.value);
                          }}
                          value={addressLine2}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 inline-block">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City*
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                          value={city}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="state"
                          name="state"
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                          value={state}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="w-1/2 inline-block">
                      <label
                        htmlFor="zip"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ZIP Code
                      </label>
                      <div className="mt-1 w-[90%]">
                        <input
                          type="text"
                          id="zip"
                          name="zip"
                          onChange={(e) => {
                            setZip(e.target.value);
                          }}
                          value={zip}
                          className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="w-1/2 inline-block mb-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country*
                      </label>
                      <div className="mt-2 w-[90%]">
                        <select
                          id="country"
                          name="country"
                          onChange={(e) => {
                            setCountry(e.target.value);
                          }}
                          className="h-[60%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="addAddressBook"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Add this address to your address book?
                      </label>
                    </div>
                    <div>
                      <input
                        id="addAddressBook"
                        name="addAddressBook"
                        onChange={() => setAddAddressBook(!addAddressBook)}
                        type="checkbox"
                        className="mt-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>

                    {addAddressBook ? (
                      <div className="mt-4 w-1/2 inline-block">
                        <label
                          htmlFor="newAddressName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address Name
                        </label>
                        <div className="mt-1 w-[90%]">
                          <input
                            type="text"
                            id="newAddressName"
                            name="newAddressName"
                            onChange={(e) => {
                              setNewAddressName(e.target.value);
                            }}
                            value={zip}
                            className="h-9 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className="mt-10">
                      <hr></hr>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="shippedWith"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Shipping Method
                        </label>
                        <select
                          id="shippedWith"
                          name="shippedWith"
                          onChange={handleChange}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue="Canada"
                        >
                          <option value={0}>Select a service</option>
                          {dataAvailableServices?.services?.map((service) => (
                            <option
                              key={service.serviceID}
                              value={service.serviceID}
                            >
                              {service.carrierName +
                                " -- " +
                                service.serviceName}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="specialInstructions"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Special Instructions
                        </label>
                        <div className="mt-2">
                          <textarea
                            rows={4}
                            name="specialInstructions"
                            onChange={handleChange}
                            id="specialInstructions"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue={""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="liftGateRequired"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Lift Gate Required
                        </label>
                      </div>
                      <div>
                        <input
                          id="liftGateRequired"
                          name="liftGateRequired"
                          onChange={() =>
                            setLiftGateRequired(!liftGateRequired)
                          }
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                    </div>
                    <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                      <button
                        type="submit"
                        className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
                      >
                        Place Order
                      </button>
                      <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left"></p>
                    </div>
                  </div>
                </section>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Checkout;
